import styled from '@emotion/styled'
import { PayTableContainer, RedDice7 } from "assets/images";

export const SingleSeven = styled.div`
  position: absolute;
  left: 170px;
  top: 140px;
`;
export const GuideLeftContainer = styled.div`
  position: fixed;
  bottom: 0px;
  right: 0px;
  z-index: 4;
  background-image: url(${PayTableContainer});
  background-size: 100% 100%;
  max-width: 465px;
  width: 100%;
  max-height: 690px;
  height: 100%;
  padding: 20px;
  user-select: none;
  animation: slide-to-left 1s forwards;
  font-size: 13px;

  @media screen and (max-height: 640px), screen and (max-width: 430px) {
    max-height: 100vh;
    overflow: hidden;
    height: fit-content;
    bottom: 0;
    padding: 5px 20px;
    height: 500px;
  }


  @media screen and (min-width: 1500px) {
    height: unset;
    max-width: 550px;
    max-height: 800px;
  }

  .close-modal {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    font-size: 26px;
    color: white;
    cursor: pointer;
    z-index: 3;
  }

  .group-1 {
    margin-top: 20px;
  }

  .group-2 {
    margin-top: 20px;
  }

  @keyframes slide-to-left {
    0% {
      right: -100%;
    }

    100% {
      right: 0;
    }
  }
`;

export const PayoutsHeader = styled.div`
  font-family: Roboto;
  font-size: 36.5px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
`;

export const PayoutsSubHeader = styled.div`
  font-family: Roboto;
  font-size: 14.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #9790c5;
`;

export const SectionTable = styled.table`
  padding: 0;
  width: 100%;

  thead {
    width: 100%;

    .align-middle {
      vertical-align: middle;
    }

    tr {
      width: 100%;

      th {
        color: #ffce25;
        font-family: Roboto;
        font-size: 17px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        vertical-align: top;
        padding-bottom: 10px;

        &:first-child {
          width: 70%;
        }

        &:nth-child(2) {
          width: 100%;
          text-align: left;
          padding-left: 10px;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        padding-left: 35px;
        color: #ffffff;

        &:nth-child(2) {
          font-family: Roboto;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: #fff;
          padding-left: 10px;
          position: relative;

          &::before {
            position: absolute;
            content: " ";
            display: block;
            width: 17px;
            height: 3px;
            background-color: #9790c5;
            opacity: 0.4;
            margin-left: -80px;
            margin-top: 5px;
            top: 50%;
            transform: translateY(-50%);

            @media screen and (max-height: 640px), screen and (max-width: 430px) {
              margin-left: -56px;
              width: 15px;
            }
          }
        }


        &.free-game {
          .free-star {
            margin-left: -40px;
            margin-right: 12px;
          }

          @media screen and (max-height: 640px), screen and (max-width: 430px) {
            width: 60px;
          }
        }

        &.consecutive-seven {
          .free-star:first-child {
            margin-left: 8px;
          }
        }

        &.two-seven {
          .free-star:first-child {
            margin-left: 12px;
          }
        }

        &.one-seven {
          .free-star:first-child {
            margin-left: 12px;
          }
        }

        .star-streak-wrapper {
          display: inline-flex;
          margin-right: 6px;
          @media screen and (max-height: 640px), screen and (max-width: 430px) {
            margin-left: 0;
          }
        }

        @media screen and (max-height: 640px), screen and (max-width: 430px) {
          padding-left: 5px;
        }
      }
    }
  }
`;

export const SevenIcon = styled.i`
  display: inline-block;
  width: 30px;
  height: 30px;
  background-image: url(${RedDice7});
  background-size: 35px 35px;
  background-position: 0 0;
`;

export const GroupOneHeaderWrapper = styled.div`
  display: inline-flex;
  text-align: center;
`;

export const Center = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  .sidebet-label {
    text-align: left;
  }
`;

export const Label = styled.span`
  color: #ffffff;
  font-family: Roboto;
  font-size: 14.5px;
  font-weight: normal;
`;

export const Header = styled.span`
  color: #ffce25;
  font-family: Roboto;
  font-size: 14.5px;
  font-weight: bold;
  margin-right: 5px;
`;

export const LabelWrapper = styled.div`
  width: 300px;
  display: inline-block;
  margin-left: 20px;
`;

export const FifteenRollsWrapper = styled.div`
  box-sizing: border-box;
`;

export const WrapContents = styled.div`
  display: block;
  line-height: 1.2;
  @media screen and (max-height: 640px), screen and (max-width: 430px) {
    transform: scale(0.7);
  }
`